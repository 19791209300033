import './App.css';
import Home from "./components/Home"

function App() {
  return (
    <Home/>
  );
}

export default App;
